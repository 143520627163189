.hero {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.hero-sm {
  min-height: 360px;

  @include media-query(lg) {
    min-height: 480px;
  }
}

.hero-lg {
  min-height: 360px;

  @include media-query(lg) {
    min-height: 640px;
  }
}

.hero-body {
  width: 100%;
  padding: 48px 0px;
  position: relative;
  z-index: 1;
  color: #fff;

  @include media-query(lg) {
    padding: 96px 0px;
  }
}

.hero-title {
  font-size: rem(36px);
  font-weight: $fw-bold;

  @include media-query(lg) {
    font-size: rem(48px);
  }
}

.hero-image {
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, .15);
  }
}