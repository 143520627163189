/*
 * Config
 */

$color-primary: #17a7b4;
$color-black: #313131;
$color-silver: #737373;
$color-clouds: #f6f6f6;

$fw-normal: 400;
$fw-bold: 700;