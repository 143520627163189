.logo-link {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(20px);
  font-weight: $fw-normal;

  strong {
    font-weight: $fw-bold;
  }

  @include media-query(lg) {
    font-size: rem(28px);
  }
}