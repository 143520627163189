.footer {
  display: flex;
  min-height: 480px;
  padding: 48px 0px;
  background-color: $color-primary;
  font-size: rem(16px);
  font-weight: $fw-normal;
  color: #fff;

  .container {
    display: flex;
    flex-direction: column;
  }

  @include media-query(lg) {
    padding: 72px 0px;
  }
}

.footer-grid {
  margin: 0px -32px !important;

  > * {
    padding: 0px 32px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    margin: 0px -32px auto !important;

    > * {
      &:nth-child(-n + 3) {
        margin-top: 0px;
      }
    }
  }
}

.footer-spacer {
  display: block;
  margin: 32px 0px;
  border-top: 1px solid rgba(#fff, .1);

  @include media-query(lg) {
    margin: auto 0px 32px;
  }
}

.footer-legal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: rem(14px);
}

.footer-copyright {
  display: block;
  width: 100%;
  margin-top: 24px;

  @include media-query(lg) {
    width: auto;
    margin-top: 0px;
    margin-left: auto;
  }
}