.button {
  width: 100%;
  font-size: rem(18px);
  min-height: 56px;
  padding: 12px 24px;
  border-radius: 28px;
  transition: all .15s ease-in;

  svg {
    margin-left: 12px;
  }

  @include media-query(xs) {
    width: auto;
  }
}

.button-primary {
  background-color: $color-primary;
  font-weight: $fw-normal;
  color: #fff;

  &:hover {
    background-color: lighten($color-primary, 5%);
  }
}

.button-link {
  position: relative;
  display: inline-block;
  padding-right: 24px;
  font-size: rem(18px);
  font-weight: $fw-bold;
  text-align: center;
  color: $color-primary;

  &:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../img/right.svg);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 5px;
    right: 0px;
  }
}