.page-element {
  margin-bottom: 64px;
}

.page-element-grid {
  margin: 0px -32px !important;

  > * {
    padding: 0px 32px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 2) {
        margin-top: 0px;
      }
    }
  }
}

.page-element-intro {
  font-size: rem(20px);
}