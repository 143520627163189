.icon-primary {
  fill: $color-primary;
}

.icon-black {
  fill: $color-black;
}

.icon-silver {
  fill: $color-silver;
}

.icon-white {
  fill: #fff;
}