.section {
  padding: 48px 0px;

  @include media-query(lg) {
    padding: 96px 0px;
  }
}

.section-title {
  margin-bottom: 0px;
}

.section-clouds {
  background-color: $color-clouds;
}

.section-background {
  display: flex;
  align-items: center;
  min-height: 480px;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fafafa;
  color: #fff;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, .15);
  }
}