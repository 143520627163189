body {
  font-family: 'PT Sans', sans-serif;
  font-weight: $fw-normal;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3,
h4, h5, h6,
p, ul, ol {
  margin-bottom: 1.5rem;
}

h1, h3, h4 {
  font-weight: $fw-bold;
}

h1 {
  font-size: rem(36px);
}

h2 {
  font-size: rem(36px);
}

h3 {
  font-size: rem(24px);
}

.tag {
  display: inline-block;
  vertical-align: middle;
  margin: 2px;
  padding: 4px 8px;
  background-color: $color-black;
  color: #fff;
}

.cta-specialist {
  background-color: $color-clouds;
  padding: 48px;

  svg {
    margin-right: 8px;
  }
}

.back-to-overview {
  margin-top: 48px;
  text-align: center;
}