.input-text,
.input-textarea {
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-color: darken($color-clouds, 10%);

  &:focus {
    background-color: transparent;
    border-color: $color-primary;
  }
}

.input-text {
  height: 56px;
}

.input-label {
  .has-floating-labels & {
    height: 22px;
    top: -11px;
    background-color: $color-black;
    padding: 2px 6px;
    font-size: rem(14px);
    border-radius: 0px;
  }
}

.input-checkbox-icon {
  border-width: 1px;
  border-color: darken($color-clouds, 10%);

  &:before {
    border-color: $color-primary;
  }
}

.input-checkbox-input:focus + .input-checkbox-icon {
  border-color: $color-primary;
}