.service-teaser-grid {
  margin: 64px -32px 0px !important;

  > * {
    padding: 0px 32px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 3) {
        margin-top: 0px;
      }
    }
  }
}

.service-teaser-icon {
  display: inline-block;
  margin-bottom: 24px;
}

.project-teaser-grid {
  margin: 64px -8px !important;

  > * {
    padding: 0px 8px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 2) {
        margin-top: 0px;
      }
    }
  }
}

.project-teaser {
  display: inline-block;
  overflow: hidden;

  @include media-query(lg) {
    position: relative;

    &:hover {
      .project-teaser-image {
        img {
          transform: scale(1.25);
        }
  
        &:before {
          background-color: rgba($color-black, .75);
        }
      }
    }
  }
}

.project-teaser-image {
  position: relative;
  z-index: 0;
  overflow: hidden;

  img {
    transition: all .15s ease-in;
  }
}

.project-teaser-body {
  padding: 48px 32px 0px;
}

.project-teaser-grid-cta {
  text-align: center;
}

.project-teaser-action {
  @include media-query(lg) {
    display: none;
  }
}